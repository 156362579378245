<template>
    <el-dialog
        title="查看详情"
        :visible.sync="disableVisible"
        :close-on-click-modal="false">
        <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="文档名称: ">
                {{ formInfo.documentName }}
            </el-form-item>
            <el-form-item label="所有者: ">
                {{ formInfo.ownerName }}
            </el-form-item>
            <el-form-item label="违规词: ">
                <el-tag v-for="(item,index) in formInfo.keyworkList" :key="index">
                    {{ item.keyword }}
                </el-tag>
            </el-form-item>
            <el-form-item label="创建时间: ">
                {{ formInfo.createDate | date }}
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="disableVisible = false">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            disableVisible: false,
            formInfo: {}
        };
    },
    methods: {
        onInit(row){
            this.disableVisible = true;
            this.formInfo = row;
        }
    }
};
</script>