<template>
    <div>
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline logs-form">
            <el-form-item label="文档名称">
                <el-input v-model="form.documentName" placeholder="请输入文档名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>

        <div class="table-box">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                type="index"
                label="序号"
                align="center"
                width="100">
                </el-table-column>
                <el-table-column
                prop="documentName"
                label="文档名称"
                align="center">
                </el-table-column>
                <el-table-column
                prop="ownerName"
                label="所有者"
                align="center">
                </el-table-column>
                <el-table-column
                prop="keyworkList"
                label="违规词"
                align="center">
                    <template slot-scope="scope">
                        <el-tag v-for="(item,index) in scope.row.keyworkList" :key="index">
                            {{ item.keyword }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                label="创建时间"
                align="center">
                    <template slot-scope="scope">
                        {{scope.row.createDate | date}}
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                width="100"
                align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" icon="el-icon-view" @click="handleAlarmLook(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-size="form.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <!-- 详情 -->
        <alarmLook ref="alarmLookDialog"></alarmLook>
    </div>
</template>
<script>
import alarmLook from './components/look.vue';
export default {
    data() {
        return {
            form: {
                documentName: '',
                pageNum: 1,
                pageSize: 10
            },
            tableData: [],
            total: 0
        }
    },
    components: {
        alarmLook
    },
    created(){
        this.onLoadList();
    },
    methods: {
        onLoadList(){
            this.$store.dispatch('manage/public', {
                query: this.form,
                requestInterfaceName: 'getAlarmList'
            }).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                    this.total = res.total;
                }
            })
        },
        onSubmit() {
            this.onLoadList();
        },
        handleCurrentChange(val){
            this.form.pageNum = val;
            this.onLoadList()
        },
        handleAlarmLook(row) {
            this.$nextTick(()=>{
                this.$refs.alarmLookDialog.onInit(row);
            })
        }
    }
}
</script>
<style lang="less" scoped>
.logs-form {
    padding: 20px;
}
.table-box {
    margin: 0 20px 0px 20px;
    padding-bottom: 20px;
}
.pagebox {
    padding: 0px 20px 20px 20px;
}
</style>